<template>
  <div class="list-box">
    <el-button type="primary" class="userbtn" @click="addKinds">添加角色</el-button>
    <div class="navMenus">
        <ul>
          <li
            v-for="(item, index) in listData"
            :key="index"
            :class="{ active: index == isActive }"
            @click="chooseClick(index, item)"
          >
            {{ item.Name }}
            <div class="edit" v-if="item.IsDefault != 1">
            <el-dropdown>
                <span class="el-dropdown-link">
                <el-icon :size="14" :color="'#BFB18A'">
                    <MoreFilled />
                </el-icon>
                </span>
                <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="editGroup(item)">编辑</el-dropdown-item>
                    <el-dropdown-item @click="delGroup(item,index)">删除</el-dropdown-item>
                </el-dropdown-menu>
                </template>
            </el-dropdown>
            </div>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import { reactive, ref, unref, onMounted, watchEffect, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import {  MoreFilled } from "@element-plus/icons";
import { useStore } from "vuex";
import {RoleGetAllRole} from "@/helper/setting"
export default {
  name: "SideItemCop",
  components: {
    MoreFilled,
  },
  emits:["onChange"],
  setup(props, context) {
    const store = useStore();
    const route = useRoute()
    const listData = ref([]);
    const isActive = ref(0);

    onMounted(() => {
      getList()
    });
    

    //获取数据
    function getList() {}
    //方法
    function addKinds() {
        editColumnRef.value.initCop()
    }
    //确认角色
    function onSureGroup(item,name){
        getList();
    }
    //选择
    function chooseClick(index, row) {
      isActive.value = index;
      context.emit("onChange", row,isActive.value);
    }
    return {
      listData,
      isActive,
      chooseClick,
      addKinds,
      getList,
      onSureGroup,
    };
  },
};
</script>
<style lang='scss' scoped>
.list-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.userbtn {
    margin: 10px;
  }


.navMenus {
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  ul {
    li {
      padding: 10px;
      &.active {
        background: #c1ae83;
        color: #fff;
      }
    }
  }
}
.active {
  background: rgb(243, 239, 230);
}
.kind-list:hover {
  background: rgb(243, 239, 230);
}
:deep().el-dropdown-menu__item {
  padding: 0 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
}
</style>
