<template>
  <div class="container">
    <el-dialog
      :title="SID ? '编辑角色' : '添加角色'"
      width="600px"
      v-model="dialogVisible"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        class="ruleForm"
      >
        <el-form-item label="角色名称：" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写"
            maxlength="15"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import {RoleCreate} from "@/helper/setting"
import _ from "lodash";
export default {
  name: "EditColumnDialog",
  components: {},
  setup(props, context) {
    const dialogVisible = ref(false);
    const isAdd = ref(true);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      name: "",
    });
    const SID = ref(null);
    const rules = reactive({
      name: [
        { required: true, message: "请填写分类名称", trigger: "blur" },
      ],
    });

    function initCop() {
      dialogVisible.value = true;
      resetData();
    }
    function resetData() {
      ruleForm.name = "";
    }
    /*************提交*****************/
    //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
          RoleCreate({RoleName:ruleForm.name}).then(res=>{
             ElMessage({
              type: "success",
              message: "提交成功",
            });
            dialogVisible.value = false;
            context.emit("onConfirm", isAdd.value,ruleForm.name);
          })
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      submitForm,
      ruleFormRef,
      ruleForm,
      rules,
      resetData,
      SID,
      isAdd,
    };
  },
};
</script>
<style lang='scss' scoped>
.ruleForm {
  //   width: 700px;
  margin: 30px 40px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .el-input {
    width: 300px;
  }
}
</style> 