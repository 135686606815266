<template>
  <div class="slide-wrap">
     <div class="slide-left-wrap">
      <el-button type="primary" class="userbtn" @click="addUser">添加角色</el-button>
      <div class="navMenus">
        <ul>
          <li
            v-for="(item, index) in listData"
            :key="index"
            :class="{ active: index == isActive }"
            @click="onChange(index,item)"
          >
            {{ item.Name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="slide-right-wrap" >
      <div class="content" >
        <h3>角色详情</h3>
        <div class="content-detail" >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleFormRef"
            label-width="120px"
            class="ruleForm"
          >
            <el-form-item label="角色名称:" prop="data.name">
              <el-input
                v-model="ruleForm.data.name"
                placeholder="请填写角色名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="关联助手:" prop="data.robots">
                <div class="item-wrap">
                    <div class="item-group-count">
                        已选择(<span>{{ ruleForm.data.robots.length || 0 }}</span>)个助手
                    </div>
                    <el-button type="primary" @click="selectRobots">选择助手</el-button>
                </div>
              
            </el-form-item>
            <el-form-item label="权限设置:" prop="data.powerData">
              <ul>
                <li
                  class="powerItem1"
                  v-for="(item, index) in powerList"
                  :key="index"
                >
                  <el-checkbox
                    v-model="item.isSelect"
                    @change="getLevel1Item(index)"
                    >{{ item.Name }}</el-checkbox
                  >
                  <ul class="powerItem2">
                    <li v-for="(item2, index2) in item.children" :key="index2">
                      <el-checkbox
                        v-model="item2.isSelect"
                        @change="getLevel2Item(index, index2)"
                        >{{ item2.Name }}</el-checkbox
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" @click="submitAction('ruleForm')">保存</el-button>
            <el-button @click="delUser">删除角色</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SelectRobots ref="robotSelectRef" @onConfirm="robotConfirmAction" />
  <EditColumnDialog ref="editColumnRef" @onConfirm="onSureGroup" />
</template>

<script>
import { reactive, ref, unref, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import SelectRobots from "@/components/SelectRobots";
import SideItemCop from "@/components/SideItemCop.vue";
import {SiteGetAllCategory,RoleGetAllRole,RoleUpdate,RoleDelete} from "@/helper/setting"
import EditColumnDialog from "@/components/EditColumnDialog.vue";
export default {
  name: "PowerManager",
  components: {
    SideItemCop,
    SelectRobots,
    EditColumnDialog
  },
  setup() {
    const powerList = ref([]);
    const isActive = ref(0);
    const sideItemRef = ref(null);
    const robotSelectRef=ref(null)
    const editColumnRef=ref(null)
    const listData=ref([])
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      data: {
        id:"",
        name: "",
        robots: [],
        powerData: [],
      },
    });
    const rules = reactive({
      data: {
        name: [{ required: true, message: "请填写角色名称", trigger: "blur" }],
        powerData: [
          {
            required: true,
            validator: validatePower,
            trigger: "change",
          },
        ],
        robots:[{required: true,validator: validateRobots,trigger: "change"}]
      },
    });
    onMounted(() => {
      getList()
    });
    //获取列表
    function getList() {
      RoleGetAllRole({RoleID:""}).then(res=>{
        listData.value=res.List
        getPower()
      })
    }
  //做列表切换
    function onChange(i, item) {
      isActive.value = i;
      getDetail(item)
    }

    //验证
    function validatePower(rule, value, callback) {
        if (ruleForm.data.powerData.length <= 0) {
            callback(new Error("请选择权限设置"));
        } else {
            callback();
        }
    }
    function validateRobots(rule,value,callback){
        if (ruleForm.data.robots.length <= 0) {
            callback(new Error("请选择助手"));
        } else {
            callback();
        }
    }
    //获取权利
    function getPower() {
      SiteGetAllCategory().then(res=>{
        powerList.value=dealwithTree(res.List)
        onChange(isActive.value,listData.value[isActive.value]);
      })
    }
    //方法
    function addUser() {
      editColumnRef.value.initCop()
    }
    //确认角色
    function onSureGroup(){
      getList();
    }
    //整理数据
    function dealwithTree(arr){
      let result=[]
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          if(item.ParentID==0){
            item.isSelect = false;
            item.children = [];
            result.push(item);
          }else{
            let parent = result.find(item2=>item.ParentID==item2.ID)
            parent.children.push(item)
          }
        });
      }
      return result;
    }

    function getLevel1Item(index) {
      let item = powerList.value[index];
      if (item.isSelect) {
        let arr = [];
        powerList.value.forEach((item2) => {
          item2.children.forEach((item3) => {
            if (item.ID == item3.ParentID) {
              item3.isSelect = true;
              arr.push(item3.ID);
            }
          });
        });
        ruleForm.data.powerData.push(item.ID);
        for (let i = 0; i < arr.length; i++) {
          ruleForm.data.powerData.push(arr[i]);
        }
      } else {
        let arr = JSON.parse(JSON.stringify(ruleForm.data.powerData));
        console.log(arr);
        powerList.value.forEach((item2) => {
          item2.children.forEach((item3) => {
            if (item.ID == item3.ParentID) {
              item3.isSelect = false;
              let tag = arr.indexOf(item3.ID);
              if (tag > -1) {
                arr.splice(tag, 1);
              }
            }
          });
        });
        let flag = arr.indexOf(item.ID);
        console.log(flag);
        if (flag > -1) {
          arr.splice(flag, 1);
        }
        ruleForm.data.powerData = arr;
        console.log(1111,ruleForm.data.powerData);
      }
    }

    function getLevel2Item(index1, index2) {
      let item = powerList.value[index1];
      let child = item.children[index2];
      console.log(child);
      if (child.isSelect) {
        ruleForm.data.powerData.push(child.ID);
      } else {
        let flag = ruleForm.data.powerData.indexOf(child.ID);
        if (flag > -1) {
          ruleForm.data.powerData.splice(flag, 1);
        }
      }
      let flag = false;
      for (let key of item.children) {
        if (key.isSelect) {
          flag=true
          break
        }
      }
      let tagFlag = ruleForm.data.powerData.indexOf(item.ID);
      if (flag==true) {
        if (tagFlag < 0) {
          ruleForm.data.powerData.push(item.ID);
        }
        item.isSelect = true;
      } else {
        if (tagFlag > -1) {
          ruleForm.data.powerData.splice(tagFlag, 1);
        }
        item.isSelect = false;
      }
      console.log(2222,ruleForm.data.powerData)
    }

    //获取详情
    function getDetail(item){
      let obj={}
      RoleGetAllRole({RoleID:item.ID}).then(res=>{
        obj=res.Detail
        ruleForm.data.name=obj.Name
        ruleForm.data.id=obj.ID
        ruleForm.data.robots=obj.Robots
        ruleForm.data.powerData=changeData(obj.CategoryID)
      })
    }
    function changeData(arr){
      let result=[]
      if(arr&&arr.length>0){
        result=arr
        powerList.value.forEach(item=>{
          if(result.indexOf(item.ID)>-1){
            item.isSelect=true
          } else {
            item.isSelect=false
          }
          item.children.forEach(item2=>{
            if(result.indexOf(item2.ID)>-1){
              item2.isSelect=true
            }else {
               item2.isSelect=false
            }
          })
        })
      }else{
        powerList.value.forEach(item2=>{
          item2.isSelect=false
         item2.children.forEach(item3=>{
            item3.isSelect=false
          })
       })
      }
      return result
    }
    //选择助手
    function selectRobots(){
      robotSelectRef.value.initCop(1,ruleForm.data.robots);
    }
    //助手确认
    function robotConfirmAction(arr){
      if(arr.length>0){
        arr=arr.map(item=>item.SerialNo)
      }
      ruleForm.data.robots=arr
    }

    //删除角色
    function delUser() {
      ElMessageBox.confirm("是否删除这个角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          RoleDelete({RoleID:ruleForm.data.id}).then(res=>{
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            getList();
          })
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消删除",
          });
        });
    }

    //提交
    async function submitAction() {
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        let param={
          RoleID:ruleForm.data.id,
          RoleName:ruleForm.data.name,
          Robots:Object.values(ruleForm.data.robots),
          CategoryID:ruleForm.data.powerData.map(item=>item)
        }
        RoleUpdate(param).then(res=>{
          ElMessage({
            type: "success",
            message: "保存成功!",
          });
          onChange(isActive.value,listData.value[isActive.value]);
        })
      } catch (error) {
        console.log(error);
      }
    }
    return {
      sideItemRef,
      ruleFormRef,
      ruleForm,
      rules,
      submitAction,
      robotSelectRef,
      delUser,
      validatePower,
      powerList,
      getPower,
      selectRobots,
      robotConfirmAction,
      getLevel1Item,
      getLevel2Item,
      addUser,
      editColumnRef,
      onSureGroup,
      listData,
      getList,
      isActive,
      onChange
    };
  },
};
</script>
<style lang='scss' scoped>
.slide-left-wrap {
  padding: 0;
  .userbtn {
    margin: 10px;
  }
}
.navMenus {
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  ul {
    li {
      padding: 10px;
      &.active {
        background: #c1ae83;
        color: #fff;
      }
    }
  }
}
.content-detail {
  margin: 20px 0;
}
.btn {
  margin-left: 50px;
}

.powerItem1 {
  display: flex;
}
.powerItem1 > .el-checkbox {
  width: 80px;
  margin-right: 26px;
}
.powerItem2 {
  display: flex;
  flex-wrap: wrap;
}
.powerItem2 > li {
  margin-left: 20px;
}
.powerItem2 > li > .el-checkbox {
  width: 160px;
}
:deep() .el-input {
  width: 310px;
}
.item-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  :deep().el-form-item__content {
    align-items: center;
  }
  .tag-cursor {
    cursor: pointer;
  }
  .item-group-count {
    margin-right: 10px;
  }
  span {
    color: $color-common;
  }
}
</style>